//FIXME: Use strong types
/* eslint-disable @typescript-eslint/no-explicit-any */

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Injector,
  OnChanges,
  OnInit,
  Signal,
  SimpleChanges,
  ViewChild,
  inject,
  runInInjectionContext,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import * as Get from '@npm-libs/ng-getx';
import {
  DataConnectorConfig,
  DataQuery,
  DataReferenceConfig,
  DataReferencePipe,
  ViewComponent,
  ViewConfig,
  ViewSlotDirective,
  dataConnectorRegistry,
  parseDataReference,
  viewComponentInputs,
  viewRegistry,
} from '@npm-libs/ng-templater';
import { IFormDialogConfig, InitialFormState } from '@rcg/core';
import { FormDialogService } from '@rcg/forms';
import { GraphqlClientService } from '@rcg/graphql';
import { IntlModule, tr } from '@rcg/intl';
import { MessageService } from '@rcg/standalone';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ChangeEventArgs, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import {
  ActionEventArgs,
  Column,
  ColumnChooserService,
  ContextMenuItem,
  ContextMenuService,
  EditService,
  EditSettingsModel,
  FilterService,
  FilterSettingsModel,
  FilterType,
  GridComponent,
  GridModule,
  GroupService,
  GroupSettingsModel,
  PageService,
  PageSettingsModel,
  ReorderService,
  ResizeService,
  SortService,
  SortSettingsModel,
  TextAlign,
  ToolbarItems,
  ToolbarService,
} from '@syncfusion/ej2-angular-grids';
import { DataManager } from '@syncfusion/ej2-data';
import { gql } from 'apollo-angular';
import * as dot from 'dot-object';
import { Observable, combineLatest, debounceTime, firstValueFrom, map, of, share, take, takeUntil, toArray } from 'rxjs';
import { QueryData, TemplaterSFDataAdaptor } from '../sf-data-adaptor';
import { Formatter, GridFormatters } from './formatters';

const name = 'data-grid';
type Name = typeof name;

export type DataGridViewColumnProps = {
  visible?: boolean;
  headerText: DataReferenceConfig;
  headerTextTr?: string;
  field: string;
  type?: string;
  format?: string;
  width?: number;
  headerTextAlign?: TextAlign;
  textAlign?: TextAlign;
  autoFit?: boolean;
  displayAsCheckBox?: boolean;
  formatter?: string;
  allowFiltering?: boolean;
  allowSorting?: boolean;
  convertTo?: string;
};

const defaultColumnProps: Partial<DataGridViewColumnProps> = {
  visible: true,
};

export type GridFormSettings = {
  formId?: {
    add?: number | string;
    edit?: number | string | { field: string };
  };
  recordIdField?: string;
  dialogTitle?: {
    add?: string;
    edit?: string;
  };
  deleteMutation?: string;
  formDialogActions?: boolean;
  prefillData?: Record<string, unknown> | null;
  initialFormState?: InitialFormState;
  prefilledGqlVariables?: Record<string, Record<string, unknown>>;
  formParentIdPath?: string;
  refreshOnSubmit?: boolean;
};

export type DataGridViewProps = {
  data?: DataReferenceConfig;
  queryRuntimeDataPath?: string;
  showColumnChooser?: boolean;
  altRowColor?: boolean;
  autoPage?: boolean;
  pageSettings?: PageSettingsModel;
  filterSettings?: FilterSettingsModel;
  sortSettings?: SortSettingsModel;
  groupSettings?: GroupSettingsModel;
  formSettings?: GridFormSettings;
  distinctConnector?: {
    config: DataConnectorConfig;
    configPatchPaths?: {
      queryRuntimeDataPath?: string;
      dataPath?: string;
      fields?: string;
    };
  };
  //? https://ej2.syncfusion.com/angular/documentation/grid/columns
  columns: DataGridViewColumnProps[];
  settingsConfig?: {
    refDataReference?: DataReferenceConfig;
    query?: {
      queryRuntimeDataPath?: string;
      data?: DataReferenceConfig;
    };
    update?: {
      queryRuntimeDataPath?: string;
      idVariableName?: string;
      idVariableType?: string;
      idVariablePath?: string;
      dataVariableName?: string;
      dataVariableType?: string;
      dataVariablePath?: string;
    };
    insert?: {
      queryRuntimeDataPath?: string;
      dataVariableName?: string;
      dataVariableType?: string;
      dataVariablePath?: string;
    };
    delete?: {
      queryRuntimeDataPath?: string;
      idVariableName?: string;
      idVariableType?: string;
      idVariablePath?: string;
    };
  };
};

export type DataGridViewSettings = {
  ref: string;
  name: string;
  column?: {
    order?: string[];
    states?: { [field: string]: Partial<DataGridViewColumnProps> };
  };
};

export type DataGridViewConfig = ViewConfig<Name, DataGridViewProps>;

const editSettings: EditSettingsModel = {
  mode: 'Dialog',
  allowEditing: true,
  allowAdding: true,
  allowDeleting: true,
  allowEditOnDblClick: true,
};

@Get.NgAutoDispose
@Component({
  standalone: true,
  selector: `rcg-templater-view--${name}`,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: [...viewComponentInputs],
  imports: [CommonModule, ViewSlotDirective, DataReferencePipe, GridModule, ButtonModule, DropDownListModule, IntlModule],
  templateUrl: './data-grid.view.component.html',
  styleUrls: ['./data-grid.view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ColumnChooserService,
    ToolbarService,
    ContextMenuService,
    ResizeService,
    ReorderService,
    PageService,
    SortService,
    FilterService,
    GroupService,
    EditService,
  ],
})
export class DataGridViewComponent
  extends ViewComponent<Name, DataGridViewProps, DataGridViewConfig>
  implements OnInit, AfterViewInit, OnChanges
{
  @ViewChild('grid') grid?: GridComponent;
  @ViewChild('settingsTemplate') settingsTemplate?: any;

  readonly columnsR = new Get.Rx<DataGridViewProps['columns'] | undefined>([]);

  injector = inject(Injector);

  private readonly gridFormatters = inject(GridFormatters);

  private lastDataQuery?: string;

  async refresh() {
    try {
      if (!this.config.props?.queryRuntimeDataPath) {
        throw new Error('No queryRuntimeDataPath provided');
      }

      const data = await firstValueFrom(
        parseDataReference(this.dataReferenceContext, { runtime: { path: this.config.props!.queryRuntimeDataPath! } }),
      );
      this.patchRuntimeData({
        [this.config.props!.queryRuntimeDataPath!]: data,
      });
    } catch (error) {
      this.messageService.showErrorSnackbar('Error refreshing grid', error instanceof Error ? error.message : '');
    }
  }

  private doQuery(query: DataQuery) {
    const newRuntimeDataPath = this.config.props?.queryRuntimeDataPath;

    if (newRuntimeDataPath && JSON.stringify(query) !== this.lastDataQuery) {
      this.lastDataQuery = JSON.stringify(query);

      const newRuntimeData: { [x: string]: any } = {};
      dot.set(newRuntimeDataPath, query, newRuntimeData);

      this.patchRuntimeData(newRuntimeData);
    }

    return this.dataR.value;
  }

  private getFilterDataConnector(distinct?: string[]) {
    if (!distinct || !this.config.props?.distinctConnector?.config) return undefined;

    const distinctFields: string[] = [];
    const nestedDistinctFields = {};

    for (const d of distinct) {
      if (d.includes('.')) {
        const split = d.split('.');
        const last = split.pop();
        const path = split.join('.');

        dot.set(path, [last], nestedDistinctFields);
        continue;
      }

      distinctFields.push(d);
    }

    const fields = [...distinctFields, ...(Object.keys(nestedDistinctFields).length ? [nestedDistinctFields] : [])];

    const connectorConfig = JSON.parse(JSON.stringify(this.config.props.distinctConnector.config));
    const cpp = this.config.props.distinctConnector.configPatchPaths;

    if (cpp) {
      if (cpp.queryRuntimeDataPath) dot.set(cpp.queryRuntimeDataPath, 'query', connectorConfig);
      if (cpp.dataPath) dot.set(cpp.dataPath, 'data', connectorConfig);
      if (cpp.fields) dot.set(cpp.fields, fields, connectorConfig);
    }

    for (const rdc of dataConnectorRegistry) {
      const ref = rdc(connectorConfig);
      if (ref) return ref;
    }

    return null;
  }

  private async doFilterQuery(query: DataQuery): Promise<QueryData> {
    const connector = this.getFilterDataConnector(query.distinct);
    if (!connector) return undefined;

    const connected$ = connector.connect({
      data$: of({}),
      runtimeData$: of({
        query,
      }),
    });

    const shared$ = connected$.pipe(share());
    const debounced$ = shared$.pipe(debounceTime(200), take(1));

    const patches = await firstValueFrom(shared$.pipe(takeUntil(debounced$), toArray()));

    const connectorData = { data: {} };

    for (const [path, value] of patches) {
      dot.set(path, value, connectorData);
    }

    const mapColumns = this.columnsR?.value?.filter((c) => c?.convertTo) ?? [];
    const data = (connectorData?.data as { data: Record<string, unknown>[] | undefined } | undefined)?.data ?? [];

    if (data?.length > 0 && mapColumns.length > 0) {
      // convert values to correct types
      const newData: Record<string, unknown>[] = data.map((d) => {
        const newRecord: Record<string, unknown> = { ...d };

        for (const c of mapColumns) {
          const value = d[c.field] as unknown;
          if (value === undefined) continue;

          switch (c.convertTo) {
            case 'date':
              {
                newRecord[c.field] = new Date(value as string);
              }
              break;
            default:
            // do nothing
          }
        }
        return newRecord;
      });
      (connectorData.data as { data: Record<string, unknown>[] }).data = newData;
    }
    return connectorData.data;
  }

  readonly dataManager = new DataManager({
    adaptor: new TemplaterSFDataAdaptor((data) => this.doQuery(data)),
  });

  private readonly filterDataManager = new DataManager({
    adaptor: new TemplaterSFDataAdaptor((data) => this.doFilterQuery(data)),
  });

  readonly inDataR = new Get.Rx<unknown, unknown>([]);
  readonly dataR = this.inDataR.pipe(debounceTime(200));

  private get defaultToolbarItems(): (ToolbarItems | object)[] {
    return [...(this.config?.props?.settingsConfig ? [{ template: this.settingsTemplate, align: 'Right' }] : [])];
  }

  public height?: number;
  public pageSettings?: PageSettingsModel;
  public toolbarItems: (ToolbarItems | object)[] = this.defaultToolbarItems;

  public readonly contextMenuItems: ContextMenuItem[] = ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending'];

  public formatters!: { [key: string]: Formatter };

  public readonly editSettings = editSettings;

  constructor(
    private changeRef: ChangeDetectorRef,
    private elementRef: ElementRef<HTMLElement>,
    private formDialogService: FormDialogService,
    private gqlClient: GraphqlClientService,
    private messageService: MessageService,
  ) {
    super(changeRef);
  }

  readonly settingsDataR = new Get.Rx<{ id: number; data?: DataGridViewSettings }[] | undefined>(undefined);

  readonly settingsDropdownDataR = this.settingsDataR.pipe(
    map((data) => [
      { text: 'Privzeto', value: -1 },
      ...(data?.map((d) => ({
        text: d.data?.name ?? 'Brez imena',
        value: d.id,
      })) ?? []),
    ]),
  );

  readonly selectedSettingsIdR = new Get.Rx<number | undefined>(undefined);

  readonly selectedSettingsIndexR = combineLatest([this.settingsDropdownDataR.value$, this.selectedSettingsIdR.value$])
    .pipe(map(([settings, sId]) => settings?.findIndex(({ value }) => value === sId)))
    .obs();

  readonly selectedSettingsNameR = combineLatest([this.settingsDropdownDataR.value$, this.selectedSettingsIdR.value$])
    .pipe(map(([settings, sId]) => settings?.find(({ value }) => value === sId)?.text))
    .obs();

  private readonly columnSettingsDataR = combineLatest([this.settingsDataR.value$, this.selectedSettingsIdR.value$])
    .pipe(
      debounceTime(10),
      map(([data, selectedSettingsId]) => {
        if (selectedSettingsId) {
          return data?.find((d) => d.id === selectedSettingsId);
        }

        const firstId = data?.[0]?.id;
        if (firstId) this.selectedSettingsIdR.data = firstId;
        else if (data) this.selectedSettingsIdR.data = -1;

        return undefined;
      }),
      map((d) => d?.data?.column),
    )
    .obs();

  private readonly refR = new Get.Rx<string | undefined>(undefined);

  private updatedColumnSettingsR = new Get.Rx<DataGridViewSettings['column']>({});
  private columnSettingsDiffR = new Get.Rx<DataGridViewSettings['column']>({});

  private model!: Signal<Record<string, unknown> | undefined>;

  ngOnInit() {
    this.formatters = this.gridFormatters.createFormatters;

    const ref$ = parseDataReference(this.dataReferenceContext, this.config.props?.settingsConfig?.refDataReference) as Observable<string>;

    runInInjectionContext(this.injector, () => {
      this.model = toSignal(
        parseDataReference(this.dataReferenceContext, { runtime: { path: 'model' } }) as Observable<Record<string, unknown> | undefined>,
      );
    });

    this.refR.subscribeTo(ref$);

    this.refR.value$.subscribe((ref) => {
      const queryRuntimeDataPath = this.config.props?.settingsConfig?.query?.queryRuntimeDataPath;
      if (!queryRuntimeDataPath) return;

      const query: DataQuery = {
        where: [
          {
            field: 'data',
            operator: 'jsoncontains',
            value: {
              ref,
            },
          },
        ],
      };

      const newRuntimeData: { [x: string]: any } = {};
      dot.set(queryRuntimeDataPath, query, newRuntimeData);
      this.patchRuntimeData(newRuntimeData);
    });

    this.columnsR.data = this.config.props?.columns;

    this.settingsDataR.subscribeTo(
      parseDataReference(this.dataReferenceContext, this.config.props?.settingsConfig?.query?.data) as Observable<
        { id: number; data?: DataGridViewSettings }[] | undefined
      >,
    );

    this.columnSettingsDiffR.subscribeTo(
      combineLatest([this.columnSettingsDataR.value$, this.updatedColumnSettingsR.value$]).pipe(
        map(([columnSettingsData, updatedColumnSettings]) => {
          if (!updatedColumnSettings) return {};

          const diff: DataGridViewSettings['column'] = {};

          const propsColumns = this.config.props?.columns;
          if (!propsColumns) return updatedColumnSettings;

          if (updatedColumnSettings.order) {
            const ogOrder = propsColumns.map((c) => c.field);

            if (!ogOrder.every((e, i) => updatedColumnSettings.order![i] === e)) {
              diff.order = updatedColumnSettings.order;
            }
          } else if (columnSettingsData?.order) {
            diff.order = columnSettingsData.order;
          }

          const colStates = updatedColumnSettings.states ?? columnSettingsData?.states;
          if (!colStates) return diff;

          diff.states = {};

          const keyedPropsColumns = propsColumns
            .map((c) => ({ [c.field]: { ...defaultColumnProps, ...c } }))
            .reduce((a, b) => ({ ...a, ...b }));

          for (const [k, v] of Object.entries(colStates)) {
            const stateDiff: Partial<DataGridViewColumnProps> = {};
            const columnProps = keyedPropsColumns[k];

            for (const [sk, sv] of Object.entries(v)) {
              const columnProp = columnProps[sk as keyof DataGridViewColumnProps];

              if (sv !== columnProp) {
                (stateDiff as { [x: string]: unknown })[sk] = sv;
              }
            }

            if (Object.keys(stateDiff).length) {
              diff.states[k] = stateDiff;
            }
          }

          return diff;
        }),
      ),
    );

    this.selectedSettingsIdR.value$.subscribe(() => {
      if (Object.keys(this.updatedColumnSettingsR.value).length) {
        this.updatedColumnSettingsR.data = {};
      }
    });

    this.columnsR.subscribeTo(
      this.columnSettingsDiffR.value$.pipe(
        debounceTime(100),
        map((columnSettings) => {
          const columns: DataGridViewProps['columns'] = JSON.parse(JSON.stringify(this.config.props?.columns));

          if (typeof columnSettings !== 'object' || !columnSettings?.states) {
            return [columns, columnSettings] as const;
          }

          for (const [k, v] of Object.entries(columnSettings.states)) {
            const col = columns.find((c) => c.field === k);
            if (!col) continue;

            for (const [ek, ev] of Object.entries(v)) {
              (col as any)[ek] = ev;
            }
          }

          return [columns, columnSettings] as const;
        }),
        map(([columns, columnSettings]) => {
          if (!columnSettings?.order) return columns;
          const o = columnSettings.order;

          return columns.sort((a, b) => {
            let iA = o.indexOf(a.field);
            let iB = o.indexOf(b.field);

            if (iA === -1) iA = Infinity;
            if (iB === -1) iB = Infinity;

            return iA - iB;
          });
        }),
      ),
    );

    this.columnsR.value$.pipe(debounceTime(100)).subscribe(() => {
      this.grid?.refreshColumns();
    });

    this.dataR.value$.subscribe(() => {
      try {
        this.grid?.refresh();
      } catch (error) {
        //ignore - error because grid is not rendered, will render itself anyway
      }
    });

    this.inDataR.subscribeTo(parseDataReference(this.dataReferenceContext, this.config.props?.data));

    setTimeout(() => this.setHeight(), 200);
  }

  override ngAfterViewInit() {
    this.refreshToolbarItems();

    return super.ngAfterViewInit();
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (
      this.toolbarItems.includes('ColumnChooser') !== this.config.props?.showColumnChooser ||
      this.toolbarItems.includes('Edit') !== !!this.config.props?.formSettings
    ) {
      this.refreshToolbarItems();
    }

    this.setHeight();

    return super.ngOnChanges(changes);
  }

  @HostListener('window:resize')
  private setHeight() {
    const isPaged = this.config.props?.pageSettings || this.config.props?.autoPage;

    const isBigFilter =
      this.config.props?.filterSettings &&
      (['FilterBar', undefined] as (FilterType | undefined)[]).includes(this.config.props.filterSettings.type);

    const height = (this.height =
      this.elementRef.nativeElement.clientHeight -
      46 -
      (isPaged ? 49 : 0) -
      (isBigFilter ? 42 : 0) -
      (this.toolbarItems.length ? 43 : 0) -
      (this.config.props?.groupSettings ? 48 : 0));

    if (this.config.props?.autoPage) {
      this.pageSettings = {
        pageSize: Math.min(Math.floor(height / 37), 50),
      };
    } else {
      this.pageSettings = this.config.props?.pageSettings;
    }

    this.changeRef.markForCheck();
  }

  private getFormToolbarActions(formSettings: GridFormSettings): string[] {
    const { formId, deleteMutation } = formSettings;
    const actions: string[] = [];

    const defaultCondition = !formId?.add && !formId?.edit && !deleteMutation;

    if (formId?.add || defaultCondition) {
      actions.push('Add');
    }

    if (formId?.edit || defaultCondition) {
      actions.push('Edit');
    }

    if (deleteMutation) {
      actions.push('Delete');
    }

    return actions;
  }

  private refreshToolbarItems() {
    this.toolbarItems = [
      ...this.defaultToolbarItems,
      ...(this.config.props?.showColumnChooser ? ['ColumnChooser'] : []),
      ...(this.config.props?.formSettings ? this.getFormToolbarActions(this.config.props.formSettings) : []),
    ] as ToolbarItems[];

    this.changeRef.markForCheck();
  }

  settingsDropdownChange(args: ChangeEventArgs) {
    const val = args?.itemData?.value as unknown as number | undefined;
    this.selectedSettingsIdR.data = val;
  }

  addSettingsProfile() {
    const name = prompt('Vnesite ime novega profila');
    if (!name) return;

    this.updateSettings(undefined, {
      ref: this.refR.value,
      name,
      column: this.columnSettingsDiffR.value,
    });
  }

  saveSettingsProfile() {
    this.updateSettings(this.selectedSettingsIdR.value, {
      ref: this.refR.value,
      name: this.selectedSettingsNameR.value,
      column: this.columnSettingsDiffR.value,
    });
  }

  deleteSettingsProfile() {
    this.deleteSettings(this.selectedSettingsIdR.value);
  }

  private setVar(
    query: {
      variables: Exclude<DataQuery['variables'], undefined>;
    },
    name: string,
    type: string,
    path: string | undefined,
    val: unknown,
  ) {
    let value: unknown = val;

    if (path) {
      const newVal = {};
      dot.set(path, val, newVal);
      value = newVal;
    }

    query.variables[name] = {
      type: type,
      value,
    };
  }

  private updateSettings(id: number | undefined, settings: DataGridViewSettings) {
    const baseConf = this.config.props?.settingsConfig;
    if (!baseConf) return;

    const conf = id ? baseConf.update : baseConf.insert;
    if (!conf || !conf.queryRuntimeDataPath || !conf.dataVariableName || !conf.dataVariableType) {
      return;
    }

    const query: DataQuery & {
      variables: Exclude<DataQuery['variables'], undefined>;
    } = {
      variables: {},
    };

    this.setVar(query, conf.dataVariableName, conf.dataVariableType, conf.dataVariablePath, settings);

    if (id) {
      const conf = baseConf.update;
      if (!conf?.idVariableName || !conf.idVariableType) return;

      this.setVar(query, conf.idVariableName, conf.idVariableType, conf.idVariablePath, id);
    }

    const newRuntimeData: { [x: string]: any } = {};
    dot.set(conf.queryRuntimeDataPath, query, newRuntimeData);
    this.patchRuntimeData(newRuntimeData);
  }

  private deleteSettings(id: number) {
    const baseConf = this.config.props?.settingsConfig;
    if (!baseConf) return;

    const conf = baseConf.delete;
    if (!conf || !conf.queryRuntimeDataPath || !conf.idVariableName || !conf.idVariableType) {
      return;
    }

    const query: DataQuery & {
      variables: Exclude<DataQuery['variables'], undefined>;
    } = {
      variables: {},
    };

    this.setVar(query, conf.idVariableName, conf.idVariableType, conf.idVariablePath, id);

    const newRuntimeData: { [x: string]: any } = {};
    dot.set(conf.queryRuntimeDataPath, query, newRuntimeData);
    this.patchRuntimeData(newRuntimeData);
  }

  setColumnStates() {
    if (!this.grid) {
      console.error('Could not locate grid to set column states');
      return;
    }

    const columns = this.grid.columns as Column[];

    const columnStates = columns
      .map(({ field, visible }) => ({
        [field]: {
          visible,
        },
      }))
      .reduce((a, b) => ({ ...a, ...b }));

    this.updatedColumnSettingsR.data = {
      ...this.updatedColumnSettingsR.value,
      states: columnStates,
    };
  }

  reorderColumn(args: Record<string, unknown>) {
    const pickupIndex = args['toIndex'] as number;
    const dropIndex = args['fromIndex'] as number;

    if (!this.columnSettingsDataR.value) return;

    const order =
      this.updatedColumnSettingsR.value.order ?? this.columnSettingsDataR.value.order ?? this.config.props?.columns.map((c) => c.field);

    if (!order) {
      console.error('Failed to move column! Could not find existing order!');
      return;
    }

    const e = order.splice(pickupIndex, 1)[0];
    order.splice(dropIndex, 0, e);

    this.updatedColumnSettingsR.data = {
      ...this.updatedColumnSettingsR.value,
      order,
    };
  }

  filterBeforeOpen(args: ActionEventArgs) {
    const options = (
      args as unknown as {
        filterModel: {
          options?: { dataManager?: DataManager; dataSource?: DataManager };
        };
      }
    ).filterModel?.options;

    if (!options) {
      console.error('filterBeforeOpen with no options', args);
      return;
    }

    options.dataManager = this.filterDataManager;
    options.dataSource = this.filterDataManager;
  }

  private formActionBegin(args: ActionEventArgs) {
    args.cancel = true;
    const isAdd = args.requestType === 'add';

    (async () => {
      await this.openForm(isAdd, args.rowData);
    })();
  }

  private deleteRecordActionBegin(args: ActionEventArgs) {
    args.cancel = true;

    (async () => {
      await this.deleteRecord(args.data);
    })();
  }

  actionBegin(args: ActionEventArgs) {
    switch (args.requestType as string) {
      case 'columnstate':
        return this.setColumnStates();
      case 'reorder':
        return this.reorderColumn(args as unknown as Record<string, unknown>);
      case 'filterbeforeopen':
        return this.filterBeforeOpen(args);
      case 'add':
      case 'beginEdit':
        return this.formActionBegin(args);
      case 'delete':
        return this.deleteRecordActionBegin(args);
    }
  }

  private async deleteRecord(data: object | undefined) {
    try {
      if (!data || !Array.isArray(data) || !data[0]) throw new Error('Unable to locate data');

      const settings = this.config.props?.formSettings;
      if (!settings || !settings.recordIdField || !settings.deleteMutation) throw new Error('Invalid delete record settings');

      const recordId = (data[0] as Record<string, unknown>)[settings.recordIdField];
      if (!recordId) throw new Error('Unable to locate recordId');

      const deleteMutation = settings.deleteMutation!;
      const response = await firstValueFrom(
        this.gqlClient.mutate<{ data?: { id?: number } }>({
          mutation: gql(deleteMutation),
          variables: {
            id: recordId,
          },
        }),
      );

      if (!response?.data?.id) {
        throw new Error('Not deleted, Response id is null');
      }
      if (settings.refreshOnSubmit) {
        await this.refresh();
      }
      this.messageService.showInfoSnackbar(`Record ${recordId} deleted.`);
    } catch (error) {
      this.messageService.showErrorSnackbar('Error delete record', error instanceof Error ? error.message : '');
    }
  }

  private async openForm(isAdd: boolean, data: object | undefined): Promise<void> {
    try {
      if (!data) throw new Error('No grid args data');

      const settings = this.config.props?.formSettings;
      if (!settings) throw new Error('Invalid form settings');
      if (!settings.recordIdField) {
        throw new Error('recordIdField not set in form settings');
      }

      const formId = settings.formId?.[isAdd ? 'add' : 'edit'];
      if (!formId) throw new Error('formId not set in form settings');

      const form = typeof formId === 'number' || typeof formId === 'string' ? formId : dot.pick(formId.field, data);
      if (!form) throw new Error('Unable to locate form');

      let parentFormId: number | undefined = undefined;

      if (settings.formParentIdPath) {
        if (!this.model()) throw new Error('Model not set');

        parentFormId = dot.pick(settings.formParentIdPath, this.model());

        if (!parentFormId) throw new Error('Unable to locate parentFormId in model');
      }

      const dialogTitle = isAdd ? settings.dialogTitle?.add : settings.dialogTitle?.edit ?? `???`;
      const dialogTitleTr = await firstValueFrom(tr(dialogTitle!));

      const config: IFormDialogConfig = {
        formMode: isAdd ? 'insert' : 'update',
        formId: form,
        formrecordId: dot.pick(settings.recordIdField, data),
        dialogTitle: dialogTitleTr,
        formDialogActions: settings.formDialogActions,
        prefillData: settings.prefillData,
        initialFormState: settings.initialFormState,
        prefilledGqlVariables: settings.prefilledGqlVariables,
        parentFormId: parentFormId,
        onSubmitSuccessAction: async () => {
          if (settings.refreshOnSubmit) {
            await this.refresh();
          }
        },
      };

      this.formDialogService.openForm(config);
    } catch (error) {
      this.messageService.showErrorSnackbar('Error opening form', error instanceof Error ? error.message : '');
    }
  }
}

viewRegistry[name] = {
  component: DataGridViewComponent,
};
